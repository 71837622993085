import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 查询小程序区域列表
 * @param params
 */
export function getAreaList(params) {
  return request({
    url: envInfo.bgApp.legoPath + '/miniprogramArea/list',
    method: 'get',
    params,
  });
}

/**
 * 删除小程序区域
 * @param params
 */
export function deleteArea(areaId) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogramArea/delete/${areaId}`,
    method: 'delete',
  });
}

/**
 * 保存小程序区域列表
 * @param params
 */
export function saveArea(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogramArea/save`,
    method: 'post',
    data: params,
  });
}

/**
 * 查询小程序列表
 * @param params
 */
export function getMiniprogramList(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/preList`,
    method: 'get',
    params,
  });
}

/**
 * 查询已发布小程序列表
 * @param params
 */
export function getMiniprogramPublishList(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/list`,
    method: 'get',
    params,
  });
}

/**
 * 查询小程序配置详情
 * @param miniprogramId
 */
export function getMiniprogramDetailById(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/preFindOne`,
    method: 'get',
    params,
  });
}

/**
 * 保存小程序
 * @param params
 */
export function saveMiniprogram(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/save`,
    method: 'post',
    data: params,
  });
}

/**
 * 更新小程序启用/禁用状态
 * @param params
 */
export function updateMiniprogramEnableStatus(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/updateEnableStatus`,
    method: 'post',
    data: params,
  });
}

/**
 * 删除小程序
 * @param params
 */
export function deleteMiniprogram(miniprogramId) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/delete/${miniprogramId}`,
    method: 'delete',
  });
}

/**
 * 小程序移动至最前
 * @param params
 */
export function sortTopMiniprogram(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/sortTop`,
    method: 'post',
    data: params,
  });
}

/**
 * 小程序重命名
 * @param params
 */
export function renameMiniprogram(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/rename`,
    method: 'post',
    data: params,
  });
}

/**
 * 查询小程序发布记录
 * @param miniprogramId
 */
export function getMiniPublishRecordsById(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/publishRecord`,
    method: 'get',
    params,
  });
}

/**
 * 查询小程序发布版本详情
 * @param miniprogramId
 * @param versionId
 */
export function getMiniRecordVersionById(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/recordVersion`,
    method: 'get',
    params,
  });
}

/**
 * 小程序移动区域
 * @param params
 */
export function moveMiniprogramArea(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogram/moveArea`,
    method: 'post',
    data: params,
  });
}

/**
 * 查询小程序banner图List
 * @param miniprogramId
 */
export function getMiniBannerList(params) {
  return request({
    url: `${envInfo.bgApp.legoWeb}/banner/findWxBannerByParam`,
    method: 'get',
    params,
  });
}

/**
 * 查询小程序园区公告
 * @param miniprogramId
 */
export function getMiniNotifyList(params) {
  return request({
    url: `${envInfo.bgApp.legoWX}/notice/getPreTopNewsNotice`,
    method: 'get',
    params,
  });
}

/**
 * 查询小程序新闻资讯
 * @param miniprogramId
 */
export function getMiniNewsList(params) {
  return request({
    url: `${envInfo.bgApp.legoWX}/activity/getWxPreActivityByParam`,
    method: 'get',
    params,
  });
}

/**
 * 查询功能列表
 * @param miniprogramId
 */
export function getFunctionList(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/miniprogramFunction/list`,
    method: 'get',
    params,
  });
}

/**
 * 查询功能列表
 */
export function getCustomServiceList(params) {
  return request({
    url: `${envInfo.bgApp.legoCSH}/config/page`,
    method: 'get',
    params,
  });
}

/**
 * 客服配置保存
 */
export function customServiceSave(params) {
  return request({
    url: `${envInfo.bgApp.legoCSH}/config/save`,
    method: 'post',
    data: params,
  });
}

/**
 * 客服配置状态更改
 */
export function customServiceStatusUpdate(params) {
  return request({
    url: `${envInfo.bgApp.legoCSH}/config/updateStatus`,
    method: 'post',
    data: params,
  });
}

/**
 * 删除客服配置
 * @param params
 */
export function customServiceDelete(cshId) {
  return request({
    url: `${envInfo.bgApp.legoCSH}/config/delete/${cshId}`,
    method: 'delete',
  });
}

/**
 * 查询评价管理列表
 */
export function getEvaluationList(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/functionEvaluate/page`,
    method: 'get',
    params,
  });
}

/**
 * 查询未绑定功能评价项目
 */
export function getEvaluationFunction(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/functionEvaluate/list`,
    method: 'get',
    params,
  });
}

/**
 * 保存评价
 */
export function functionEvaluationSave(params) {
  return request({
    url: `${envInfo.bgApp.legoPath}/functionEvaluate/save`,
    method: 'post',
    data: params,
  });
}

/**
 * 删除评价管理
 * @param params
 */
export function functionEvaluationDelete(evaluateId) {
  return request({
    url: `${envInfo.bgApp.legoPath}/functionEvaluate/delete/${evaluateId}`,
    method: 'delete',
  });
}

/**
 * 查询停车场列表
 */
export function getParkingLotList(params) {
  return request({
    url: `${envInfo.bgApp.customerlegoPath}/parkingManage/queryParkingInfo`,
    method: 'get',
    params,
  });
}

/**
 * 新增停车场
 */
export function parkingLotAdd(params) {
  return request({
    url: `${envInfo.bgApp.customerlegoPath}/parkingManage/add`,
    method: 'post',
    data: params,
  });
}

/**
 * 编辑停车场
 */
export function parkingLotEdit(params) {
  return request({
    url: `${envInfo.bgApp.customerlegoPath}/parkingManage/edit`,
    method: 'post',
    data: params,
  });
}

/**
 * 删除停车场
 */
export function parkingLotDelete(params) {
  return request({
    url: `${envInfo.bgApp.customerlegoPath}/parkingManage/delete`,
    method: 'get',
    params,
  });
}

/**
 * 查询招商管理列表
 */
export function getInvestmentList(params) {
  return request({
    url: `${envInfo.bgApp.legoWeb}/investment/list`,
    method: 'get',
    params,
  });
}

/**
 * 新增招商管理
 */
export function addInvestment(params) {
  return request({
    url: `${envInfo.bgApp.legoWeb}/investment/insert`,
    method: 'post',
    data: params,
  });
}

/**
 * 编辑招商管理
 */
export function editInvestment(params) {
  return request({
    url: `${envInfo.bgApp.legoWeb}/investment/update`,
    method: 'post',
    data: params,
  });
}

/**
 * 招商管理置顶
 */
export function upInvestment(id) {
  return request({
    url: `${envInfo.bgApp.legoWeb}/investment/top?id=${id}`,
    method: 'get',
  });
}

/**
 * 招商管理删除
 */
export function deleteInvestment(id) {
  return request({
    url: `${envInfo.bgApp.legoWeb}/investment/delete?id=${id}`,
    method: 'get',
  });
}

/**
 * 查询商户号管理列表
 */
export function getMerchantList(params) {
  return request({
    url: `${envInfo.bgApp.customerlegoPath}/merchant/page`,
    method: 'get',
    params,
  });
}

/**
 * 商户号删除
 */
export function deleteMerchant(id) {
  return request({
    url: `${envInfo.bgApp.customerlegoPath}/merchant/delete?id=${id}`,
    method: 'get',
  });
}

/**
 * 新增/编辑商户号
 */
export function updateMerchant(params) {
  return request({
    url: `${envInfo.bgApp.customerlegoPath}/merchant/add`,
    method: 'post',
    data: params,
  });
}

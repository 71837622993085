var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-serives-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.dialogCloseHandler,
            width: "860px",
            title: "客服配置",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: {
                "label-position": "top",
                model: _vm.form,
                rules: _vm.formRule,
              },
            },
            [
              _c(
                "div",
                { staticClass: "gray-card" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人及联系方式", prop: "cshConfig" } },
                    _vm._l(_vm.form.cshConfig, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "config-row",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-input", {
                            staticClass: "config-input",
                            attrs: { placeholder: "请输入名称" },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "config-input",
                            attrs: { placeholder: "请输入联系电话" },
                            model: {
                              value: item.phone,
                              callback: function ($$v) {
                                _vm.$set(item, "phone", $$v)
                              },
                              expression: "item.phone",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "config-input",
                            attrs: { placeholder: "请输入微信号" },
                            model: {
                              value: item.wx,
                              callback: function ($$v) {
                                _vm.$set(item, "wx", $$v)
                              },
                              expression: "item.wx",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.cshConfig.length > 1,
                                  expression: "form.cshConfig.length > 1",
                                },
                              ],
                              staticClass: "delele-button",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteSingleConfig(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "add-line" }, [
                    _c(
                      "span",
                      {
                        staticClass: "add-inner",
                        on: { click: _vm.addNewConfig },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v("\n            添加\n          "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择项目", prop: "projectId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择项目" },
                      model: {
                        value: _vm.form.projectId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "projectId", $$v)
                        },
                        expression: "form.projectId",
                      },
                    },
                    _vm._l(_vm.projectList, function (item) {
                      return _c("el-option", {
                        key: item.projectId,
                        attrs: {
                          value: item.projectId,
                          label: item.projectName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "介绍", prop: "description" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入介绍" },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上传图片", prop: "cshIcon" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.uploadloading,
                          expression: "uploadloading",
                        },
                      ],
                      staticClass: "upload-container",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.uploadPath,
                            "with-credentials": true,
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            "before-upload": _vm.beforeAvatarUpload,
                          },
                        },
                        [
                          _vm.form.cshIcon
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: {
                                  src: _vm.imageRender(_vm.form.cshIcon),
                                },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                      _c("div", { staticClass: "tips-container" }, [
                        _c("div", [
                          _c("div", [_vm._v("温馨提示：")]),
                          _c("div", [
                            _vm._v(
                              "最多上传1张，图片大小不能超过2m，图片格式为jpg，png。"
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.cshIcon,
                                expression: "form.cshIcon",
                              },
                            ],
                            staticClass: "delete-icon",
                            on: { click: _vm.deleteHandler },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "button-line" },
            [
              _c(
                "r-button",
                {
                  attrs: { type: "cancel" },
                  on: { click: _vm.dialogCloseHandler },
                },
                [_vm._v("取消")]
              ),
              _c(
                "r-button",
                {
                  attrs: {
                    plain: "",
                    loading: _vm.uploadloading || _vm.submitLoading,
                  },
                  on: { click: _vm.submitHandler },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
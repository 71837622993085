<template>
  <div class="new-serives-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="860px"
      title="客服配置"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :model="form"
        ref="dialogForm"
        :rules="formRule"
      >
        <div class="gray-card">
          <el-form-item label="联系人及联系方式" prop="cshConfig">
            <div
              class="config-row"
              :gutter="20"
              v-for="(item, index) in form.cshConfig"
              :key="index"
            >
              <el-input
                class="config-input"
                placeholder="请输入名称"
                v-model="item.name"
              ></el-input>
              <el-input
                class="config-input"
                placeholder="请输入联系电话"
                v-model="item.phone"
              ></el-input>
              <el-input
                class="config-input"
                placeholder="请输入微信号"
                v-model="item.wx"
              ></el-input>
              <el-button
                v-show="form.cshConfig.length > 1"
                type="text"
                class="delele-button"
                @click="deleteSingleConfig(index)"
                >删除</el-button
              >
            </div>
          </el-form-item>
          <div class="add-line">
            <span class="add-inner" @click="addNewConfig">
              <i class="el-icon-plus"></i>
              添加
            </span>
          </div>
        </div>
        <el-form-item label="选择项目" prop="projectId">
          <el-select
            style="width: 100%"
            v-model="form.projectId"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :value="item.projectId"
              :label="item.projectName"
              :key="item.projectId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="介绍" prop="description">
          <el-input
            type="textarea"
            placeholder="请输入介绍"
            v-model="form.description"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="cshIcon">
          <div class="upload-container" v-loading="uploadloading">
            <el-upload
              class="avatar-uploader"
              :action="uploadPath"
              :with-credentials="true"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.cshIcon"
                :src="imageRender(form.cshIcon)"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tips-container">
              <div>
                <div>温馨提示：</div>
                <div>最多上传1张，图片大小不能超过2m，图片格式为jpg，png。</div>
                <!-- <div>图片尺寸212*112</div> -->
              </div>
              <div
                v-show="form.cshIcon"
                class="delete-icon"
                @click="deleteHandler"
              >
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="button-line">
        <r-button type="cancel" @click="dialogCloseHandler">取消</r-button>
        <r-button
          plain
          @click="submitHandler"
          :loading="uploadloading || submitLoading"
          >保存</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import { customServiceSave } from "@/api/ruge/lego/config";

export default {
  name: "new-serives-dialog",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
    projectList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      submitLoading: false,
      uploadloading: false,
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      form: {
        cshConfig: [
          {
            name: null,
            phone: null,
            wx: null,
          },
        ],
        projectId: null,
        description: null,
        cshIcon: null,
        cshId: null,
        cshStatus: "DISABLE",
      },
      formRule: {
        projectId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        cshIcon: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (!this.form.cshIcon) {
                callback(new Error("请上传图片"));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
        cshConfig: [
          {
            required: true,
            validator: (rule, value, callback) => {
              for (let item of this.form.cshConfig) {
                const { name, phone, wx } = item;
                if (!name) {
                  callback(new Error("请填写名称"));
                  break;
                }
                if (!phone) {
                  callback(new Error("请填写联系电话"));
                  break;
                }
                if (!wx) {
                  callback(new Error("请填写微信号"));
                  break;
                }
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    initDatas() {
      if (this.dataset.datas && this.dataset.datas.cshId) {
        const { cshConfig, cshIcon, description, projectId, cshId, cshStatus } =
          this.dataset.datas;
        this.form.cshIcon = cshIcon;
        this.form.description = description;
        this.form.projectId = projectId;
        this.form.cshId = cshId;
        this.form.cshStatus = cshStatus;
        this.form.cshConfig = JSON.parse(cshConfig);
      }
    },
    deleteSingleConfig(index) {
      this.form.cshConfig.splice(index, 1);
    },
    addNewConfig() {
      this.form.cshConfig.push({
        name: null,
        phone: null,
        wx: null,
      });
    },
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
    dialogCloseHandler(flag) {
      this.$emit("close", flag);
    },
    handleAvatarSuccess(files) {
      this.form.cshIcon = files[0].fileId;
      this.uploadloading = false;
      this.$refs.dialogForm.validate();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("封面图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("封面图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.uploadloading = returnFlag;
      return returnFlag;
    },
    deleteHandler() {
      this.form.cshIcon = null;
    },
    async submitHandler() {
      await this.$refs.dialogForm.validate();
      this.submitLoading = true;
      const params = { ...this.form };
      params.cshConfig = JSON.stringify(params.cshConfig);
      console.log("params", params);
      customServiceSave(params)
        .then(() => {
          this.$message.success("保存成功！");
          this.$emit("close", true);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.new-serives-dialog {
  .gray-card {
    background: #f5f7f9;
    padding: 10px;
    width: calc(100% + 20px);
    margin-left: -10px;
    padding-bottom: 25px;
    border-radius: 10px;
  }
  .config-row {
    .config-input {
      display: inline-block;
      margin-right: 16px;
      width: 240px;
    }
    .delele-button {
      color: #ff0000;
      display: inline-block;
    }
  }
  .config-row + .config-row {
    margin-top: 20px;
  }
  .add-line {
    text-align: center;
    margin-top: 20px;
    .add-inner {
      color: #2a61ff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-icon-plus {
        font-weight: bold;
        font-size: 16px;
        margin-right: 2px;
      }
    }
  }
  .button-line {
    text-align: right;
    margin: 20px 0;
  }
}
</style>
<style lang="less">
.upload-container {
  display: flex;
  .tips-container {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #5d687c;
    & > div {
      height: 20px;
      line-height: 20px;
    }
    .delete-icon {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      color: #ff0000;
      cursor: pointer;
    }
  }
}
.avatar-uploader {
  height: 100px;
  width: 100px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  height: 100px;
  width: 100px;
  display: block;
}
</style>